import React, { Component } from "react";
import "./filterall.scss";
import CloseButton from "../sharedComponents/CloseButton";
import ClearAllButton from "./ClearAllButton";
import FilterAllController from "./FilterAllController";
import FilterList from "../FilterTypeView/FilterList";

class FilterAllView extends Component {
  controller = new FilterAllController(this.props.main);

  renderClearAllFilter = () => {
    const content = [];

    const keys = Object.keys(this.props.geoJSONDataStatistics);

    keys.forEach((shortName, index) => {
      content.push([
        <ClearAllButton
          key={'clearAllButton_' + index}
          fullCategoryName={this.props.geoJSONData[shortName].fullName}
          categoryShortName={shortName}
          clearLayer={this.controller.clearLayer}
          resetFilter={this.controller.resetFilter}
          clearedLayers={this.props.clearedLayers}
        ></ClearAllButton>,
      ]);
    });
    return content;
  };

  sortIntoFilterList = (filterObjects) => {
    const relevantTypes = {
      project_status: {
        name: "Status",
        values: [],
        uniqueValues: new Map(),
      },
    };

    filterObjects.forEach((filterObject) => {
      const { property, value } = { ...filterObject };

      for (const key in relevantTypes) {
        if (key === property) {
          const id = property + "-" + value.toLowerCase();
          filterObject.filterID = id;
          relevantTypes[key].uniqueValues.set(value, filterObject);
          relevantTypes[key].values = Array.from(
            relevantTypes[key].uniqueValues.values()
          );
        }
      }
    });

    return relevantTypes;
  };

  setWheterElementIsChecked = (filterList) => {
    const allFilterButton = new Map(this.props.allFilterButton);

    for (const key in filterList) {
      filterList[key].values.forEach((entry) => {
        entry.checked =
          allFilterButton.get(entry.filterID) === undefined
            ? true
            : allFilterButton.get(entry.filterID);
      });
    }
    return filterList;
  };

  renderStatusFilter() {
    const savedFilter = JSON.parse(JSON.stringify(this.props.defaultFilter)); //clone defaultFilter
    let allFilters = [];

    for (const key in savedFilter) {
      allFilters = [...allFilters, ...savedFilter[key]]; //merge Arrays
    }

    let filterList = this.sortIntoFilterList(allFilters);
    filterList = this.setWheterElementIsChecked(filterList);

    const content = [];
    const filterType = "FilterAll";

    Object.keys(filterList).forEach((key) => {
      const list = filterList[key];
      const id = filterType + "-" + key;

      content.push(
        <FilterList
          {...this.props}
          filterList={list.values}
          listReadableName={list.name}
          key={id}
          setFilter={this.controller.setFilter}
          filterType={filterType}
        ></FilterList>
      );
    });
    return content;
  }

  render() {
    return (
      <div className={"filter-view filter-view-all"}>
        <CloseButton
          closeButton={this.controller.closeFilterAllContainer}
        ></CloseButton>
        <div className={"clear-all-filter-container"}>
          <h4>Clear All</h4>
          {this.renderClearAllFilter()}
        </div>

        {this.renderStatusFilter()}
      </div>
    );
  }
}

export default FilterAllView;
