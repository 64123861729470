import mapboxgl from "mapbox-gl";

import MapboxConfig_prod from "./MapboxConfig_prod.json";
import MapboxConfig_local from "./MapboxConfig_local.json";

const MapboxConfig = MapboxConfig_prod;

if (process.env.NODE_ENV !== "production") {
  MapboxConfig.geoJSONEndpoint = MapboxConfig_local.geoJSONEndpoint;
  MapboxConfig.searchEndpoint = MapboxConfig_local.searchEndpoint;
}

mapboxgl.accessToken = MapboxConfig.mapboxAccessToken;

class MapboxAPI {
  map = {};

  sourceIDs = new Map();
  layerIDs = new Map();
  popupsActive = [];
  setDescription = (description) => {};
  mapIsLoaded = () => {};

  geoJSONDataLayers = MapboxConfig.geoJSONDataLayers;

  initializeMap(mapContainer) {
    this.map = new mapboxgl.Map({
      logoPosition: "bottom-left",
      container: mapContainer,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [5, 34],
      zoom: 2,
      maxZoom: 14,
    });
    // Add zoom and rotation controls to the map.
    this.map.addControl(new mapboxgl.NavigationControl(), "top-left");

    this.map.on("load", () => {
      this.mapIsLoaded();
    });

    return this.map;
  }

  addLayer(json) {
    const lowerCaseName = json.name.toLowerCase();
    const sourceID = lowerCaseName;
    const layerID = "geoJSON-layer-" + lowerCaseName;
    const layerIDSinglePoint =
      "geoJSON-layer-" + lowerCaseName + "-single-point";
    const layerIDClusterCount =
      "geoJSON-layer-" + lowerCaseName + "-cluster-count";

    this.layerIDs.set(lowerCaseName, [
      layerID,
      layerIDSinglePoint,
      layerIDClusterCount,
    ]);
    this.sourceIDs.set(lowerCaseName, sourceID);

    this.map.addSource(sourceID, {
      type: "geojson",
      data: json,
      cluster: true,
      clusterMaxZoom: 6, // Max zoom to cluster points on; see Cluster-Example: https://docs.mapbox.com/mapbox-gl-js/example/cluster/
      clusterRadius: 40,
    });
    this.map.addLayer({
      id: layerID,
      type: "circle",
      source: sourceID,
      paint: {
        // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
        // with three steps to implement three types of circles:
        //   * 15px circles when point count is less than 10
        //   * 20px circles when point count is between 10 and 100
        //   * 30px circles when point count is greater than or equal to 100
        "circle-radius": ["step", ["get", "point_count"], 15, 10, 20, 100, 30],
        "circle-color": this.geoJSONDataLayers[lowerCaseName].circle_color,
        "circle-stroke-width": 2,
        "circle-stroke-color": "#fff",
      },
    });

    this.map.addLayer({
      id: layerIDClusterCount,
      type: "symbol",
      source: sourceID,
      filter: ["has", "point_count"],
      layout: {
        "text-field": "{point_count_abbreviated}",
        "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
        "text-size": 20,
      },

      paint: {
        "text-color": "#ffffff",
      },
    });

    let paint = {
      "circle-color": this.geoJSONDataLayers[lowerCaseName].circle_color,
      "circle-radius": 5,
      "circle-stroke-width": 1,
      "circle-stroke-color": "#fff",
    }

    if (window.innerWidth <= 1000) {
      paint["circle-radius"] = 9
    }

    this.map.addLayer({
      id: layerIDSinglePoint,
      type: "circle",
      source: sourceID,
      filter: ["!", ["has", "point_count"]],
      paint: paint,
    });

    this.addSinglePointOnClickListener(layerIDSinglePoint);
    this.addClusterZooming(layerID, sourceID);
  }

  addClusterZooming(layerID, sourceID) {
    // inspect a cluster on click
    this.map.on(
      "click",
      layerID,
      function (layerID, sourceID, event) {
        if (event.originalEvent.cancelBubble) {
          return;
        }
        const map = this.map;
        var features = map.queryRenderedFeatures(event.point, {
          layers: [layerID],
        });
        var clusterId = features[0].properties.cluster_id;

        map
          .getSource(sourceID)
          .getClusterExpansionZoom(clusterId, function (err, zoom) {
            if (err) return;
            if (typeof zoom !== "number" || !isFinite(zoom)) return; //fixes random "failed to invert matrix"-errors, see: https://github.com/mapbox/mapbox-gl-js/issues/6782
            const zoomFaster = zoom + 1;
            map.easeTo({
              center: features[0].geometry.coordinates,
              zoom: zoomFaster,
            });
          });
      }.bind(this, layerID, sourceID)
    );

    this.map.on(
      "mouseenter",
      layerID,
      function () {
        this.map.getCanvas().style.cursor = "pointer";
      }.bind(this)
    );
    this.map.on("mouseleave", layerID, () => {
      this.map.getCanvas().style.cursor = "";
    });
  }

  addSinglePointOnClickListener(layerIDSinglePoint) {
    //addpopup on hover
    this.map.on(
      "mouseenter",
      layerIDSinglePoint,
      function (element) {
        this.map.getCanvas().style.cursor = "pointer";
        var coordinates = element.features[0].geometry.coordinates.slice();
        var title = element.features[0].properties.title;

        // Ensure that if the map is zoomed out such that multiple
        // copies of the feature are visible, the popup appears
        // over the copy being pointed to.
        while (Math.abs(element.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += element.lngLat.lng > coordinates[0] ? 360 : -360;
        }
        const popup = new mapboxgl.Popup({ closeButton: false })
          .setLngLat(coordinates)
          .setHTML(title)
          .addTo(this.map);

        this.popupsActive.push(popup);
      }.bind(this)
    );

    this.map.on(
      "click",
      layerIDSinglePoint,
      function (element) {
        element.originalEvent.cancelBubble = true;
        this.setDescription(element.features[0].properties);
      }.bind(this)
    );

    //remove popup
    this.map.on(
      "mouseleave",
      layerIDSinglePoint,
      function () {
        this.popupsActive.forEach((popup) => {
          popup.remove();
        });
        this.map.getCanvas().style.cursor = "";
      }.bind(this)
    );
  }

  getAllLayerIDs() {
    return this.layerIDs;
  }
  getAllSourceIDs() {
    return this.sourceIDs;
  }

  getSourceID(id) {
    return this.sourceIDs.get(id);
  }

  getLayerID(id) {
    return this.layerIDs.get(id);
  }

  getGeoJSONDataLayers(id) {
    let datalayer = {};

    this.geoJSONDataLayers.forEach((element) => {
      if (element.name === id) {
        datalayer = element;
      }
    });
    return datalayer;
  }
}

export default MapboxAPI;
