import React, {Component} from "react";

class AboutMapButton extends Component {
  onClick = () => {
    const controlButton = {type: "aboutMap", hasControl: true};
    this.props.onClick(controlButton);
  };

  render() {
    return (
      <div
        className={
          "control-button control-button--misc control-button--about-map"
        }
        onClick={this.onClick}
        data-name={"aboutMap"}
      >
        <span className="fullname">About</span>
      </div>
    );
  }
}

export default AboutMapButton;
