export default class FilterTypeController {
  app = {};

  constructor(app) {
    this.app = app;
  }

  closeContainer = () => {
    this.app.setControlsContent({ hasControl: false });
  };

  setFilter = (filterObject) => {
    this.app.applyFilter(filterObject);
  };

  setDateFilter = (start, end) => {
    const filterType = start.filterType;
    this.app.resetDateFilter(filterType);
    this.app.applyFilter(start);
    this.app.applyFilter(end);

    const dateFilter = { ...this.app.state.dateFilter };
    dateFilter[filterType] = [start.value, end.value];
    this.app.setState({ dateFilter });
  };
}
