// customized class from https://github.com/bewithjonam/mapboxgl-spiderifier to just calculate right circle distance for given coordinates
// used for separating points which have the same coordinates or appear very near to each other

class Spiderifier {
  NULL_FUNCTION = function () {};
  options = {
    animate: false, // to animate the spiral
    animationSpeed: 0, // animation speed in milliseconds
    customPin: false, // If false, sets a default icon for pins in spider legs.
    initializeLeg: this.NULL_FUNCTION,
    onClick: this.NULL_FUNCTION,
    // --- <SPIDER TUNING Params>
    // circleSpiralSwitchover: show spiral instead of circle from this marker count upwards
    //                        0 -> always spiral; Infinity -> always circle
    circleSpiralSwitchover: 9,
    circleFootSeparation: 25, // related to circumference of circle
    spiralFootSeparation: 28, // related to size of spiral (experiment!)
    spiralLengthStart: 15, // ditto
    spiralLengthFactor: 4, // ditto
    // ---
  };
  twoPi = Math.PI * 2;
  previousSpiderLegs = [];
  spiderfy = function (features) {
    var spiderLegParams = this.generateSpiderLegParams(features.length);
    return spiderLegParams;
  };

  generateSpiderLegParams = function (count) {
    if (count >= this.options.circleSpiralSwitchover) {
      return this.generateSpiralParams(count);
    } else {
      return this.generateCircleParams(count);
    }
  };

  generateSpiralParams = function (count) {
    var legLength = this.options.spiralLengthStart,
      angle = 0;
    const spiralFootSeparation = this.options.spiralFootSeparation;
    const twoPi = this.twoPi;
    const spiralLengthFactor = this.options.spiralLengthFactor;
    return this.mapTimes(count, function (index) {
      var pt;
      angle = angle + (spiralFootSeparation / legLength + index * 0.0005);
      pt = {
        x: legLength * Math.cos(angle),
        y: legLength * Math.sin(angle),
        angle: angle,
        legLength: legLength,
        index: index,
      };
      legLength = legLength + (twoPi * spiralLengthFactor) / angle;
      return pt;
    });
  };

  generateCircleParams = function (count) {
    var circumference = this.options.circleFootSeparation * (2 + count),
      legLength = circumference / this.twoPi, // = radius from circumference
      angleStep = this.twoPi / count;

    return this.mapTimes(count, function (index) {
      var angle = index * angleStep;
      return {
        x: legLength * Math.cos(angle),
        y: legLength * Math.sin(angle),
        angle: angle,
        legLength: legLength,
        index: index,
      };
    });
  };

  // Utility
  each = function (array, iterator) {
    var i = 0;
    if (!array || !array.length) {
      return [];
    }
    for (i = 0; i < array.length; i++) {
      iterator(array[i], i);
    }
  };

  eachTimes = function (count, iterator) {
    if (!count) {
      return [];
    }
    for (var i = 0; i < count; i++) {
      iterator(i);
    }
  };

  map = function (array, iterator) {
    var result = [];
    this.eachFn(array, function (item, i) {
      result.push(iterator(item, i));
    });
    return result;
  };

  mapTimes = function (count, iterator) {
    var result = [];
    this.eachTimes(count, function (i) {
      result.push(iterator(i));
    });
    return result;
  };
}

export default Spiderifier;
