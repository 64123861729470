import React, { Component } from "react";
import CloseButton from "../sharedComponents/CloseButton";
import ShareButton from "../sharedComponents/ShareButton";
import DescriptionController from "./DescriptionController";
import "./description.scss";

class DescriptionView extends Component {
  firstPropertiesToRender = new Map();
  lastPropertiesToRender = new Map();
  controller = {};

  constructor(props) {
    super(props);
    this.controller = new DescriptionController(this.props.main, this);
    this.firstPropertiesToRender
      .set("Description", "description")
      .set("Type", "type1")
      .set("Status", "project_status")
      .set("Scale", "scale")
      .set("Location", "location")
      .set("Period of time", "period_of_time")
      .set("Sponsor(s)", "sponsors");

    this.lastPropertiesToRender.set("Last Updated", "date_last_updated");

    this.state = {
      relatedProjectsLayerIDs: false,
    };
  }

  renderProperties = (props, properties) => {
    const content = [];

    properties.forEach((property, key) => {
      content.push(<h4 key={key}>{key}</h4>);
      if (Array.isArray(property)) {
        if (props[property[0]]) {
          const prop_content = JSON.parse(props[property[0]]) || [];
          prop_content.forEach((inner_property, key) => {
            content.push(
              <p
                dangerouslySetInnerHTML={{
                  __html: inner_property.value,
                }}
                key={inner_property + "-" + key}
              ></p>
            );
          });
        }
      } else {
        if (props[property]) {
          content.push(
            <p
              dangerouslySetInnerHTML={{
                __html: props[property],
              }}
              key={key + "-" + property}
            ></p>
          );
        }
      }
    });
    return content;
  };

  renderSources = (sources = []) => {
    const content = [];

    if (sources && sources.length !== 0) {
      content.push(
        <h4 className="properties__sources-headline" key="Website">
          {sources.length > 1 ? "Websites" : "Website"}
        </h4>
      );

      sources.forEach((source, index) => {
        content.push(
          <a
            className="properties__sources-link"
            key={index}
            href={source.value}
            target="_blank"
            rel="noopener noreferrer"
          >
            {source.value}
          </a>
        );
      });
    }
    return content;
  };

  renderTypeDescription = () => {
    const content = [];
    content.push(this.props.descriptionProperties.type1Description);
    return content;
  };

  renderRelatedProjects = (projects) => {
    if (projects) {
      const origin = this.props.descriptionProperties;
      this.controller.showRelatedProjects(projects, origin);
    }
  };

  removeRelatedProjects = () => {
    if (this.state.relatedProjectsLayerIDs) {
      this.controller.removeRelatedProjectLayers(
        this.state.relatedProjectsLayerIDs
      );
    }
  };

  render() {
    let layerID = "";
    if (this.props.descriptionProperties.layerID) {
      layerID = this.props.descriptionProperties.layerID.replace(
        "geoJSON-source-",
        ""
      );
    }

    this.removeRelatedProjects();

    if (this.props.descriptionProperties["relatedProjects"]) {
      this.renderRelatedProjects(
        this.props.descriptionProperties["relatedProjects"]
      );
    }

    return (
        <div className={"description--container " + layerID}>
          <CloseButton
            closeButton={this.controller.closeDescription}
          ></CloseButton>
          <div className="properties">
            <ShareButton
              shareLink={this.props.descriptionProperties.shareUrl}
            ></ShareButton>
            <h3>{this.props.descriptionProperties.title}</h3>
            {this.renderProperties(
              this.props.descriptionProperties,
              this.firstPropertiesToRender
            )}

            {this.renderSources(this.props.descriptionProperties.sources)}

            {this.renderProperties(
              this.props.descriptionProperties,
              this.lastPropertiesToRender
            )}
          </div>
          { this.props.descriptionProperties.type1Description.map(
            type1Description => {
              const key = Object.keys(type1Description)[0];
              return <div className="type-description" dangerouslySetInnerHTML={{__html: type1Description[key],}} />
            }
          )}
        </div>
    );
  }
}

export default DescriptionView;
