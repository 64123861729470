import React, { Component } from "react";
import FilterListItem from "./FilterListItem";

class FilterList extends Component {
  renderFilterListItem = () => {
    const filterList = [];
    this.props.filterList.forEach(function (element, index) {
      filterList.push(
        <FilterListItem key={'filterlist_' + index} element={element} {...this.props}></FilterListItem>
      );
    }, this);
    return filterList;
  };

  render() {
    return (
      <div className="filter--list">
        <h4>{this.props.listReadableName}</h4>
        <ul>{this.renderFilterListItem()}</ul>
      </div>
    );
  }
}

export default FilterList;
