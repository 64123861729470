import React, { Component } from "react";

class SearchButton extends Component {
  onClick = () => {
    const controlButton = { type: "search", hasControl: true };
    this.props.onClick(controlButton);
  };

  render() {
    return (
        <div
          className={
            "control-button control-button--misc control-button--search"
          }
          onClick={this.onClick}
          data-name={"search"}
        >
          <span className="fullname">Search</span>
        </div>
    );
  }
}

export default SearchButton;
