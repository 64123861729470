import React, { Component } from "react";

class ClearAllButton extends Component {
  isChecked = () => {
    const checked = this.props.clearedLayers[this.props.categoryShortName];
    return !checked;
  };

  onChange = (checked) => {
    if (!checked) {
      this.props.clearLayer(this.props.categoryShortName);
    } else {
      this.props.resetFilter(this.props.categoryShortName);
    }
  };

  render() {
    const id = "clear-all-" + this.props.categoryShortName;
    const classNames =
      "filter--list filter--list--" + this.props.categoryShortName;
    let fullName = this.props.fullCategoryName
      ? this.props.fullCategoryName
      : "";
    fullName = fullName ? '"' + fullName + '"-' : " ";

    const label = this.isChecked()
      ? "Clear " + fullName + "Projects from map"
      : "Add " + fullName + "Projects to map";

    return (
      <div className={classNames}>
        <input
          type="checkbox"
          id={id}
          onChange={(changeEvent) =>
            this.onChange(changeEvent.currentTarget.checked)
          }
          checked={this.isChecked()}
        ></input>
        <label htmlFor={id}>{label}</label>
      </div>
    );
  }
}

export default ClearAllButton;
