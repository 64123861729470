import MapboxConfig_prod from "./MapboxConfig_prod.json";
import MapboxConfig_local from "./MapboxConfig_local.json";
import Spiderifier from "../inc/Spiderifier";

const MapboxConfig = MapboxConfig_prod;

if (process.env.NODE_ENV !== "production") {
  MapboxConfig.geoJSONEndpoint = MapboxConfig_local.geoJSONEndpoint;
  MapboxConfig.searchEndpoint = MapboxConfig_local.searchEndpoint;
}

class GeoJSONData {
  geoJSONData = {};
  geoJSONEndpoint = MapboxConfig.geoJSONEndpoint;
  geoJSONDataLayers = MapboxConfig.geoJSONDataLayers;
  addLayerToMap = (json) => {};
  initialSetupDone = () => {};

  async fetchGeoJSONData() {
    for (const key in this.geoJSONDataLayers) {
      const data = this.geoJSONDataLayers[key];
      const url = this.geoJSONEndpoint + data.name;
      const response = await fetch(url);
      let json = await response.json();
      this.geoJSONData[data.name] = json;
    }

    for (const key in this.geoJSONData) {
      this.geoJSONData[key] = this.separateOverlappingPoints(
        this.geoJSONData[key]
      );
    }

    for (const key in this.geoJSONData) {
      this.addLayerToMap(this.geoJSONData[key]);
    }

    this.initialSetupDone();
  }

  getGeoJSONDataLayers(id) {
    let datalayer = {};

    for (const key in this.geoJSONDataLayers) {
      const element = this.geoJSONDataLayers[key];
      if (element.name === id) {
        datalayer = element;
      }
    }
    return datalayer;
  }

  getData() {
    return this.geoJSONData;
  }

  separateOverlappingPoints(json) {
    const type = json.name;
    const newPoints = json.features;

    const projectsOnSameLocation = new Map();
    const projectsIgnore = new Map();

    let allPoints = [];
    if (Object.keys(this.geoJSONData).length === 0) {
      allPoints = json.features;
    }
    Object.keys(this.geoJSONData).forEach((layer) => {
      const data = this.geoJSONData[layer].features;
      allPoints.push(...data);
    });

    allPoints.forEach((pointA) => {
      newPoints.forEach((pointB) => {
        const sameFeature = pointA.properties.id === pointB.properties.id;
        if (sameFeature || projectsIgnore.get(pointA.properties.id)) {
          return;
        }

        const latitudeDifference = Math.abs(
          pointA.geometry.coordinates[0] - pointB.geometry.coordinates[0]
        );
        const longitudeDifference = Math.abs(
          pointA.geometry.coordinates[1] - pointB.geometry.coordinates[1]
        );

        if (latitudeDifference < 0.007 && longitudeDifference < 0.0035) {
          pointA.properties.projectsOnSameLocations =
            pointA.properties.projectsOnSameLocations || [];

          const dataObject = {
            target_id: pointB.properties.id,
            type: type,
            coordinates: pointB.geometry.coordinates,
          };

          pointA.properties.projectsOnSameLocations.push(dataObject);

          const data = projectsOnSameLocation.get(pointA.properties.id) || [];

          data.push(dataObject);

          projectsOnSameLocation.set(pointA.properties.id, data);
          projectsIgnore.set(pointB.properties.id, true); //add one overlapping point to ignore list so we don't rearrange our points multiple times
        }
      });
    });
    const spiderifier = new Spiderifier();
    // eslint-disable-next-line no-unused-vars
    for (const [key, values] of projectsOnSameLocation) {
      const distances = spiderifier.spiderfy(values);
      values.forEach((point, index) => {
        let distanceMagnifier = 5000; //the higher the lower the distance between points
        if (values.length === 1) {
          distanceMagnifier = 500; //if we only have one other point on top of us we need to increase the distance for a better visual experience
        }

        point.coordinates[0] =
          point.coordinates[0] - distances[index].x / distanceMagnifier;
        point.coordinates[1] =
          point.coordinates[1] - distances[index].y / distanceMagnifier;

        this.geoJSONData[point.type].features.forEach((entry) => {
          if (entry.id === point.target_id) {
            entry.geometry.coordinates[0] = point.coordinates[0];
            entry.geometry.coordinates[1] = point.coordinates[1];
          }
        });
      });
    }

    return json;
  }
}

export default GeoJSONData;
