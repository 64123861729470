import MapboxConfig_prod from "../MapAPI/MapboxConfig_prod.json";
import MapboxConfig_local from "../MapAPI/MapboxConfig_local.json";

const MapboxConfig = MapboxConfig_prod;

if (process.env.NODE_ENV !== "production") {
  MapboxConfig.geoJSONEndpoint = MapboxConfig_local.geoJSONEndpoint;
  MapboxConfig.searchEndpoint = MapboxConfig_local.searchEndpoint;
}

export default class SearchController {
  app = {};

  constructor(app) {
    this.app = app;
    this.map = app.map;
    this.mapAPI = app.mapAPI;
    this.searchEndpoint = MapboxConfig.searchEndpoint;
  }

  closeSearchContainer = () => {
    this.app.setControlsContent({ hasControl: false });
  };

  setSearchResults(searchResults) {
    const searchLoading = false;
    this.app.setState({ searchResults, searchLoading });
  }

  async fireSearch(searchValue) {
    this.app.setState({ searchResults: [], searchLoading: true });
    let query = "?search_api_fulltext=" + searchValue;

    const url = this.searchEndpoint + query;

    const response = await fetch(url);
    const json = await response.json();

    if (json.length < 1) {
      const message = `No results for "${searchValue}"!`;
      json.push({
        title: message,
        body: "",
        nid: "-1",
      });
    }

    this.setSearchResults(json);
    this.setFilterBySearchTerm(json, searchValue);
  }

  openDescription = (properties) => {
    this.app.setDescription(properties);
  };

  flyTo = (options) => {
    this.map.flyTo(options);
  };

  savePreviousTypeFilter() {
    if (!this.app.state.searchTerm) {
      const previousFilter = { ...this.app.state.savedFilter };
      this.app.setState({ previousFilter });
    }
  }

  buildGeoJSONMapByID(geoJSONData) {
    const map = new Map();

    for (const type in geoJSONData) {
      geoJSONData[type].features.forEach((element) => {
        map.set(element.properties.id, type);
      });
    }
    return map;
  }

  setFilterBySearchTerm(json, searchTerm = "") {
    this.savePreviousTypeFilter();

    const geoJSONMap = this.buildGeoJSONMapByID(this.app.state.geoJSONData);

    const filter = {};
    //const filterExample = {ggr: [filterobjects]}

    json.forEach((element) => {
      const filterType = geoJSONMap.get(element.nid);

      filter[filterType] = filter[filterType] || [];
      filter[filterType].push({
        filterID: "ggr-search-" + element.nid,
        filterType: filterType,
        checked: true, //filter will only be applied, if it is checked
        parentType: "Type",
        property: "id",
        value: element.nid,
      });
    });

    this.app.setState({ searchTerm });

    const sourceIDs = this.mapAPI.getAllSourceIDs();
    for (const [sourceID] of sourceIDs) {
      const filterObject = {
        filterID: "none",
        filterType: sourceID,
        checked: true,
      };
      this.app.applyFilter(filterObject, filter);
    }
  }

  resetFilter() {
    const savedFilter = { ...this.app.state.previousFilter };

    this.app.setState({ savedFilter });
    const sourceIDs = this.mapAPI.getAllSourceIDs();

    for (const [sourceID] of sourceIDs) {
      const filterObject = {
        filterID: "none",
        filterType: sourceID,
        checked: true,
      };
      this.app.applyFilter(filterObject);
    }
  }

  clearSearch() {
    this.app.setState({ searchTerm: "" });
    this.resetFilter();
    this.setSearchResults([]);
  }
}
