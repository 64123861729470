import React from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import App from "./App.js";

export default function Main() {
  return (
    <Router>
      <Routes>
        <Route path={'/'} element={<App />} />
        <Route path={'/*'} element={<App />} />
      </Routes>
    </Router>
  );
}
