import React, { Component } from "react";
import "./filterview.scss";
import FilterList from "./FilterList";
import CloseButton from "../sharedComponents/CloseButton";
import FilterTypeController from "./FilterTypeController";

class FilterTypeView extends Component {
  constructor(props) {
    super(props);
    this.controller = new FilterTypeController(this.props.main);
  }

  sortIntoFilterList(filterObjects) {
    const relevantTypes = {
      type1: { name: "Type", values: [] },
      project_status: { name: "Status", values: [] },
    };

    //only show "Area"-filter on ggr-type
    if (this.props.controlButton.filterType === "ggr") {
      relevantTypes["type2"] = { name: "Area", values: [] };
    }

    filterObjects.forEach((filterObject) => {
      const { property } = { ...filterObject };

      for (const key in relevantTypes) {
        if (key === property) {
          relevantTypes[key].values.push(filterObject);
        }
      }
    });

    for (const key in relevantTypes) {
      //delete empty value-arrays so not to render empty filters
      if (relevantTypes[key].values.length === 0) {
        delete relevantTypes[key];
      }

      //sort value-arrays alphabetically
      relevantTypes[key].values.sort((x, y) => {
        const valueX = x.value;
        const valueY = y.value;

        if (valueX < valueY) return -1;
        if (valueX > valueY) return 1;
        return 0;
      });
    }

    return relevantTypes;
  }
  renderFilterList = (allPointsOfType) => {
    const filterType = this.props.controlButton.filterType;

    const filterList = this.sortIntoFilterList(
      this.props.savedFilter[filterType]
    );

    const content = [];

    Object.keys(filterList).forEach((key) => {
      const list = filterList[key];
      const id = filterType + "-" + key;
      content.push(
        <FilterList
          {...this.props}
          filterList={list.values}
          listReadableName={list.name}
          key={id}
          setFilter={this.controller.setFilter}
          filterType={filterType}
        ></FilterList>
      );
    });
    return content;
  };

  render() {
    const filterType = this.props.controlButton.filterType;
    const allPointsOfType = this.props.geoJSONData[filterType];

    return (
      <div className={"filter-view filter-view--" + filterType}>
        <CloseButton closeButton={this.controller.closeContainer}></CloseButton>
        {this.renderFilterList(allPointsOfType.features)}
      </div>
    );
  }
}

export default FilterTypeView;
