import React from "react";
import SearchView from "../SearchView/SearchView";
import FilterAllView from "../FilterAllView/FilterAllView.js";
import FilterTypeView from "../FilterTypeView/FilterTypeView.js";
import AboutMapView from "../AboutMapView/AboutMapView";

const components = {
  search: SearchView,
  aboutMap: AboutMapView,
  filterAll: FilterAllView,
  filterType: FilterTypeView,
};

function DynamicControlsContent(props) {
  const button = props.controlButton;
  if (button.hasControl) {
    const ControlsView = components[button.type];
    return <ControlsView {...props} />;
  }
  return null;
}

export default DynamicControlsContent;
