export default class DescriptionController {
  app = {};
  view = {};
  layerIDs = new Map();
  relatedProjectSinglePointslayerIDs = new Map();
  sourceIDs = new Map();

  constructor(app, view) {
    this.app = app;
    this.map = app.map;
    this.view = view;
  }

  closeDescription = () => {
    this.app.setDescription(false);
    const layerIDs = this.view.state.relatedProjectsLayerIDs;
    if (layerIDs) {
      this.removeRelatedProjectLayers(layerIDs);
      this.view.setState({ relatedProjectsLayerIDs: false });
    }
  };

  removeRelatedProjectLayers = (layerIDs) => {
    // eslint-disable-next-line no-unused-vars
    for (const [key, layerID] of layerIDs) {
      if (this.map.getLayer(layerID)) {
        this.map.removeLayer(layerID);
      }
    }
  };

  getDataByID = (id) => {
    const geoJSON = { ...this.app.state.geoJSONData };
    let foundData = "";
    Object.keys(geoJSON).forEach((key) => {
      geoJSON[key]["features"].forEach((data) => {
        if (data.properties.id === id) {
          data.properties.color = this.app.state.config[key].circle_color;
          foundData = data;
        }
      });
    });
    return foundData;
  };

  setRelatedProjectsLayerIDs = () => {
    this.view.setState(this.saveRelatedProjectLayerIDs);
  };

  saveRelatedProjectLayerIDs = (state) => {
    // Stop updates and re-renders if we already have layerIDs
    if (
      state.relatedProjectsLayerIDs &&
      state.relatedProjectsLayerIDs.size > 0
    ) {
      return null;
    }

    return {
      relatedProjectsLayerIDs: this.layerIDs,
    };
  };

  showRelatedProjects = (relatedProjects = "", origin = "") => {
    origin = this.getDataByID(origin.id);
    this.addRelatedProjectsSinglePoints(
      origin,
      origin.properties.id + "-origin-"
    );

    relatedProjects.forEach((project) => {
      const layerID = project["target_id"];
      this.layerIDs.set(layerID, layerID);
      const target = this.getDataByID(project["target_id"]);

      if (target) {
        this.addRelatedProjectsLines(origin, target, layerID);
        this.addRelatedProjectsSinglePoints(target, layerID);
      }
    });

    this.setRelatedProjectsLayerIDs();
  };

  addRelatedProjectsLines(origin, target, layerID) {
    const originCoordinate = origin.geometry.coordinates;

    const geometry = {
      type: "LineString",
      coordinates: [originCoordinate, target.geometry.coordinates],
    };

    const sourceID = layerID + "-" + Date.now();

    const source = {
      type: "geojson",
      data: {
        type: "Feature",
        properties: {},
        geometry: geometry,
      },
      lineMetrics: true,
    };
    this.map.addSource(sourceID, source);

    if (!this.map.getLayer(layerID)) {
      this.map.addLayer({
        id: layerID,
        type: "line",
        source: sourceID,
        layout: { "line-cap": "round" },
        paint: {
          "line-width": 4,
          "line-gradient": [
            "interpolate",
            ["linear"],
            ["line-progress"],
            0,
            origin.properties.color,
            1,
            target.properties.color,
          ],
        },
      });
    }
  }

  addRelatedProjectsSinglePoints(data, layerID) {
    const sourceIDSinglePoint =
      layerID + "-related-project-singlepoint--source";

    if (!this.map.getSource(sourceIDSinglePoint)) {
      this.map.addSource(sourceIDSinglePoint, {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: [data],
        },
      });
    }

    const layerIDSinglePoint = layerID + "-related-project-singlepoint--layer";
    if (!this.map.getLayer(layerIDSinglePoint)) {
      const layer = {
        id: layerIDSinglePoint,
        type: "circle",
        source: sourceIDSinglePoint,
        paint: {
          "circle-color": data.properties.color,
          "circle-radius": 5,
          "circle-stroke-width": 2,
          "circle-stroke-color": "#fff",
        },
      };

      this.map.addLayer(layer);
      this.layerIDs.set(layerIDSinglePoint, layerIDSinglePoint);

      this.map.on(
        "click",
        layerIDSinglePoint,
        function (element) {
          element.originalEvent.cancelBubble = true;
          element.features[0].properties.layerID =
            element.features[0].properties.parentTerm;
          this.app.setDescription(element.features[0].properties);
        }.bind(this)
      );

      this.map.on(
        "mouseenter",
        layerIDSinglePoint,
        function () {
          this.map.getCanvas().style.cursor = "pointer";
        }.bind(this)
      );
      this.map.on("mouseleave", layerIDSinglePoint, () => {
        this.map.getCanvas().style.cursor = "";
      });
    }
  }
}
