import React, { Component } from "react";

class FilterListItem extends Component {
  getStatistic = (element) => {
    if (this.props.geoJSONDataStatistics[this.props.filterType]) {
      const statistics =
        this.props.geoJSONDataStatistics[this.props.filterType];

      return statistics[element] ? statistics[element] : "0";
    }

    return null;
  };

  generateFilter = (element, checked) => {
    const filterObject = { ...element };
    filterObject.checked = checked;
    filterObject.filterType = this.props.filterType;
    this.props.setFilter(filterObject);
  };

  render() {
    const id = this.props.element.filterID;
    const element = this.props.element;

    const statistics = this.getStatistic(element.value);

    return (
      <li key={id}>
        <input
          type="checkbox"
          checked={element.checked}
          id={id}
          onChange={(changeEvent) => {
            this.generateFilter(
              this.props.element,
              changeEvent.currentTarget.checked
            );
          }}
        ></input>
        <label htmlFor={id}> {element.value}</label>
        {statistics && <span>{statistics}</span>}
      </li>
    );
  }
}

export default FilterListItem;
