import React, { Component } from "react";
import { CSSTransition } from "react-transition-group";

class SearchResults extends Component {
  constructor(props) {
    super(props);
    this.state = {
      geoJSONDataAsMap: this.buildGeoJSONMap(this.props.geoJSONData),
    };

    this.prepareSearchResults();
  }

  prepareSearchResults() {

    const searchResults = [];

    this.props.searchResults.forEach(result => {

      for (const [key, value] of Object.entries(this.props.geoJSONData)) {
        value.features.forEach(feature => {
          if (feature.properties.id === result.nid) {
            feature.parentType = key;
            searchResults.push(feature);
          }
        })
      }
    })

    this.searchResults = searchResults;
  }

  buildGeoJSONMap = (geoJSONData) => {
    const map = new Map();

    for (const type in geoJSONData) {
      geoJSONData[type].features.forEach((element) => {
        map.set(element.properties.id, type);
      });
    }
    return map;
  };

  getLongLat = (element) => {
    const field_geolocation = JSON.parse(
      this.htmldecode(element.field_geolocation)
    );
    return `{[${field_geolocation.coordinates}]}`;
  };

  openDescription(currentTarget) {
    const id = currentTarget.dataset.id;
    const parentType = currentTarget.dataset.parenttype;
    let element = {};
    this.props.geoJSONData[parentType].features.forEach((data) => {
      if (data.properties.id === id) {
        element = data.properties;
      }
    });

    element.layerID = parentType;

    this.props.openDescription(element);
  }

  flyTo = (currentTarget) => {
    const id = currentTarget.dataset.id;
    let longlat = [];
    this.searchResults.forEach((element) => {
      if (element.properties.id === id) {
        longlat = element.geometry.coordinates;
      }
    });

    const options = { center: longlat, zoom: 9, bearing: 0, essential: true };
    this.props.flyTo(options);
    this.openDescription(currentTarget);
  };

  htmldecode = (str) => {
    var txt = document.createElement("div");
    txt.innerHTML = str.replace(/(.{350})..+/, "$1…");
    return txt.textContent || txt.innerText || "";
  };

  renderSearchResults = () => {
    const content = [];
    this.searchResults.forEach((element) => {

      content.push(
        <CSSTransition
          in={true}
          timeout={300}
          classNames="move-up fade-in"
          appear={true}
          unmountOnExit={true}
        >
          <div
            className={["search-results--container " + element.parentType]}
            onClick={(event) => this.flyTo(event.currentTarget, event)}
            data-id={element.properties.id}
            data-parenttype={element.parentType}
          >
            <h4 className="search-results--title">
              {this.htmldecode(element.properties.title)}
            </h4>
            <div className="search-results--description">
              {this.htmldecode(element.properties.description)}
            </div>
          </div>
        </CSSTransition>
      );
    });
    return content;
  };

  renderNumberOfSearchResults() {
    if (
      this.searchResults.length > 0 &&
      this.searchResults[0].nid !== "-1"
    ) {
      const resultsText =
        this.searchResults.length === 1
          ? " 1 Result"
          : this.searchResults.length + " Results";
      return (
        <p className="search-results--number">
          {resultsText} for "{this.props.searchTerm}"
        </p>
      );
    }
  }

  render() {
    return (
      <div className="search-results">
        {this.renderNumberOfSearchResults()}
        {this.renderSearchResults()}
      </div>
    );
  }
}

export default SearchResults;
