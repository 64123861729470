import React, {Component} from "react";
import {ReactComponent as SunIcon} from "../Images/Icon_sun.svg";
import {ReactComponent as CO2Icon} from "../Images/Icon_co2.svg";
import {ReactComponent as WeatherIcon} from "../Images/Icon_weather.svg";
import {ReactComponent as OtherIcon} from "../Images/Icon_other.svg";

class TypeButton extends Component {
  getIcon = () => {
    switch (this.props.shortName) {
      case "ggr":
        return <CO2Icon></CO2Icon>;
      case "srm":
        return <SunIcon></SunIcon>;
      case "wm":
        return <WeatherIcon></WeatherIcon>;
      case "other":
        return <OtherIcon></OtherIcon>;
      default:
        return [];
    }
  };

  onClick = (filterType) => {
    const controlButton = {type: "filterType", hasControl: true};
    controlButton["filterType"] = filterType;
    this.props.onClick(controlButton);
  };

  render() {
    return (
      <div
        className={"control-button control-button--" + this.props.shortName}
        onClick={(event) => {
          const filterType = this.props.shortName;
          this.onClick(filterType);
        }}
        data-name={this.props.shortName}
      >
        {this.getIcon()}
        <span className="fullname">{this.props.fullName}</span>
        <span className="number-of-datapoints">
            {this.props.numberOfDataPoints}
          </span>
      </div>
    );
  }
}

export default TypeButton;
