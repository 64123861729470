export default class FilterAllController {
  app = {};

  constructor(app) {
    this.app = app;
  }

  closeFilterAllContainer = () => {
    this.app.setControlsContent({ hasControl: false });
  };

  clearLayer = (filterType) => {
    const filterObject = {
      filterID: "clearall",
      filterType: filterType,
      checked: true, //filter will only be applied, if it is checked
      parentType: "Type ",
    };

    const filter = [
      {
        filterID: "clearall-" + filterType,
        filterType: filterType,
        checked: true,
        parentType: "None",
        property: "none",
        value: "none",
      },
    ];

    const clearedLayers = { ...this.app.state.clearedLayers };

    clearedLayers[filterType] = !clearedLayers[filterType] || false;

    this.app.setState({ clearedLayers });
    this.app.applyFilter(filterObject, filter);
  };

  setFilter(filterObject) {
    const app = this.main;

    const allFilterButton = new Map(app.state.allFilterButton);
    const clearedLayers = { ...app.state.clearedLayers };

    const filterID = filterObject.filterID;
    for (const key in this.geoJSONData) {
      const applyFilter = !clearedLayers[key] ? true : false;
      filterObject.filterID = key + "-" + filterID;
      filterObject.filterType = key;
      allFilterButton.set(filterID, filterObject.checked);
      app.applyFilter(filterObject, false, applyFilter);
    }

    app.setState({ allFilterButton });
  }

  resetFilter = (filterType) => {
    const savedFilter = { ...this.app.state.savedFilter };
    const checkedButtons = this.app.state.allFilterButton;

    savedFilter[filterType].forEach((filter) => {
      const filterAllID = filter.filterID.replace(filterType + "-", "");
      const buttonChecked = checkedButtons.get(filterAllID);

      if (buttonChecked) {
        filter.checked = true;
      }
    });

    const clearedLayers = { ...this.app.state.clearedLayers };

    clearedLayers[filterType] = false;

    this.app.setState({ savedFilter, clearedLayers });

    const filterObject = {
      filterID: "none",
      filterType: filterType,
      checked: true,
    };

    this.app.applyFilter(filterObject, savedFilter);
  };
}
