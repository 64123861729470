import React, { Component } from "react";
import CloseButton from "../sharedComponents/CloseButton";
import WelcomeWindowController from "./WelcomeWindowController";

class WelcomeWindow extends Component {
  controller = new WelcomeWindowController(this.props.main);

  render() {
    return (
          <div className="welcome-window">
            <CloseButton
              closeButton={this.controller.closeWelcomeWindow}
            ></CloseButton>

            <h3>Geoengineering Map</h3>
            <p>
              This interactive world map on geoengineering, prepared by ETC
              Group and the Heinrich Boell Foundation, sheds light on the
              alarming expansion of geoengineering research and experimentation.
            </p>
            <p>
              It builds on an earlier map of Earth Systems Experimentation
              published in 2012. That original map documented around 300
              projects and experiments related to the field of geoengineering.
              Almost a decade later, more than 1,700 such projects have been
              identified -- including past, ongoing and planned ones. When
              opening the map, only ongoing and planned projects are displayed,
              as well as those that have been completed or cancelled in the last
              five years
            </p>
            <p>
              These include Carbon Removal and Solar Radiation Management as
              well as other geoengineering approaches. The map also contains
              Carbon Capture and Weather Modification projects. There is no
              complete record of weather and climate control projects so this
              map is necessarily partial.
            </p>
            <p>
              For more information and background on geoengineering and its
              risks and impacts on ecological, economic, social and justice see{" "}
              <a href="https://geoengineeringmonitor.org">
                geoengineeringmonitor.org
              </a>
            </p>

            <input
              type="checkbox"
              id="dont-show-welcome-window-again"
              onChange={(changeEvent) =>
                this.controller.dontShowWelcomeWindowAgain(
                  changeEvent.currentTarget.checked
                )
              }
            ></input>
            <label htmlFor="dont-show-welcome-window-again">
              {" "}
              Don't show this message again?
            </label>
          </div>
    );
  }
}

export default WelcomeWindow;
