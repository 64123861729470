import React, { Component } from "react";
import "./searchview.scss";
import CloseButton from "../sharedComponents/CloseButton";
import SearchResults from "./SearchResults";
import SearchController from "./SearchController";

class SearchView extends Component {
  constructor(props) {
    super(props);
    this.searchController = new SearchController(props.main);
    this.state = {
      searchValue: "",
    };
    this.timer = null;
  }

  handleChange = (event) => {
    const searchValue = event.currentTarget.value;

    if (searchValue.length <= 1) {
      return;
    }
    this.setState({ searchValue });
    this.setTimeThreshold();
  };

  handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  setTimeThreshold = () => {
    let timeoutInMs = 3000;

    if (this.timer) {
      timeoutInMs = 1500;
      timeoutInMs -= this.timer;
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(this.fireSearch, timeoutInMs);
  };

  fireSearch = () => {
    this.searchController.fireSearch(this.state.searchValue);
  };

  clearSearch = (clearInputValue = true) => {
    if (clearInputValue) this.inputElement.value = "";
    this.setState({ searchValue: "" });
    this.searchController.clearSearch();
  };

  render() {
    let spinnerClassnames = "fa fa-cog fa-spin";
    spinnerClassnames += this.props.searchLoading
      ? " loader show"
      : " loader hide";

    const placeholder = this.props.searchTerm
      ? this.props.searchTerm
      : "Type here";

    return (
      <div className={"filter-view filter-search "}>
        <CloseButton
          closeButton={this.searchController.closeSearchContainer}
        ></CloseButton>
        <div className="search-input">
          <form className="search-form" onSubmit={this.handleSubmit}>
            <input
              ref={(el) => (this.inputElement = el)}
              id="search-mapbox"
              name="search-mapbox"
              onChange={this.handleChange}
              placeholder={placeholder}
            ></input>
            <i className={spinnerClassnames} />
          </form>
          {!this.props.searchLoading && this.props.searchTerm && (
            <button className="search-clear" onClick={this.clearSearch}>
              Clear Search
            </button>
          )}
        </div>
        {this.props.searchResults.length > 0 && (
          <SearchResults
            geoJSONData={this.props.geoJSONData}
            searchResults={this.props.searchResults}
            openDescription={this.searchController.openDescription}
            flyTo={this.searchController.flyTo}
            searchTerm={this.props.searchTerm}
          ></SearchResults>
        )}
      </div>
    );
  }
}

export default SearchView;
