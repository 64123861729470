import React, {Component} from "react";
import {ReactComponent as ShareButtonIcon} from "../Images/Share_button.svg";
import {ReactComponent as CopyToClipBoardIcon} from "../Images/CopyToClipboard.svg";

class ShareButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showShareLink: false,
      shareLink: "",
      shareLinkCopied: false,
    };
  }

  showShareLink = (shareLink) => {
    shareLink = 'https://' + window.location.host + shareLink;
    this.setState({showShareLink: !this.state.showShareLink, shareLink});
  };

  // This is the function we wrote earlier
  async copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  handleCopyClick = (copyText) => {
    // Asynchronously call copyTextToClipboard
    this.copyTextToClipboard(copyText).then(() => {
      // If successful, update the isCopied state value
      console.log('copied')
      this.setState({shareLinkCopied: true})
      setTimeout(() => {
        this.setState({shareLinkCopied: false})
      }, 1500);
    })
      .catch((err) => {
        console.log(err);
      });
  }


  render() {
    return (
      <>
        <div className="shareLink--wrapper">
          <a
            href={'/'}
            className="shareLink--button"
            onClick={(event) => {
              event.preventDefault()
              this.showShareLink(this.props.shareLink);
            }}
          >
            <ShareButtonIcon/>
          </a>
        </div>
        {this.state.showShareLink && (
          <div className={"shareLink--link"}>
            <p>
              Share this project with this link:
            </p>
            <p>
              {this.state.shareLink}
            </p>
            <div className={'shareLink--copy'}>
              {this.state.shareLinkCopied && <span>Copied!</span>}
              <CopyToClipBoardIcon onClick={(event) => {
                event.preventDefault();
                this.handleCopyClick(this.state.shareLink)
              }}/>
            </div>

          </div>
        )}
      </>
    );
  }
}

export default ShareButton;
