import React, {Component} from "react";

class FilterAllButton extends Component {
  onClick = () => {
    const controlButton = {type: "filterAll", hasControl: true};
    this.props.onClick(controlButton);
  };

  render() {
    return (
      <div
        className={"control-button control-button--misc control-button--all"}
        onClick={this.onClick}
        data-name={"filter-all"}
      >
        <span className="fullname">Filter all</span>
        <span className="number-of-datapoints">
            {this.props.numberOfDataPoints}
          </span>
      </div>
    );
  }
}

export default FilterAllButton;
