import React, { Component } from "react";
import {Slider} from "@mui/material";
import CloseButton from "./CloseButton";

class DateFilter extends Component {
  minValue = 2000;
  currentYear = new Date().getFullYear();

  constructor(props) {
    super(props);
    const dateValues = this.props.dateValues;
    this.state = {
      filterType: this.props.filterType,
      dateValues: dateValues,
      minimizeFilter: false
    };
  }

  filterDates = (event, value) => {
    const filterType = this.props.filterType;

    const start = value[0] === this.minValue ? 0 : value[0];
    const end = value[1] === this.currentYear ? 3000 : value[1];

    const startDate = {
      property: "field_project_start",
      value: start,
      checked: true,
      parentType: "Date",
      operator: ">=",
      filterID: `${filterType}-field_project_start-${start}`,
      filterType: filterType,
    };
    const endDate = {
      property: "field_project_end",
      value: end,
      checked: true,
      parentType: "Date",
      operator: "<=",
      filterID: `${filterType}-field_project_end-${end}`,
      filterType: filterType,
    };
    this.setState({ dateValues: [start, end] });
    this.props.setDateFilter(startDate, endDate);
  };

  render() {
    if (this.props.filterType !== this.state.filterType) {
      this.setState({
        dateValues: this.props.dateValues,
        filterType: this.props.filterType,
      });
    }
    return (
      <div className={'datefilter' + (this.state.minimizeFilter ? ' minimize' : '')}>
        <CloseButton closeButton={() => this.setState({minimizeFilter: !this.state.minimizeFilter})} useCustomContent={this.state.minimizeFilter ? 'Filter By Date' : false} />
        <h4 className="datefilter__headline">{"Filter by Date"}</h4>
        <Slider
          getAriaLabel={() => "Always visible"}
          max={this.currentYear}
          min={this.minValue}
          value={this.state.dateValues}
          step={1}
          marks={[
            { label: "2000 and earlier", value: this.minValue },
            {
              label: `recent projects`,
              value: this.currentYear,
            },
          ]}
          valueLabelDisplay="on"
          onChangeCommitted={this.filterDates}
          onChange={(event, values) => this.setState({ dateValues: values })}
        />
      </div>
    );
  }
}

export default DateFilter;
